/* .page-item.disabled {
    display: block !important;
    width: 58px !important;
    text-align: center !important;
    margin-right: 20px !important;
}
.page-item.active, .page-item {
    display: block !important;
    width: 58px !important;
    text-align: center !important;
    margin-right: 20px !important;
}
.page-link {
    background: white !important;
    border-radius: 50% !important;
    color: black !important;
}

.paginate-option{
    border-radius: 26px !important;
    font-size: 25px;
    margin-bottom: 20px;
    margin-right: 27px;
    width: 55px !important;
    height: 52px !important;
    text-align: center;
} */

.page-link {
    line-height: 20px !important;
}
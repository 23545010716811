.modal-content {
    min-height: 200px !important;
}

.nested-button button {
    background: white !important;
    color: black !important;
    border: none !important;
}

.report-filter-section .dropdown-menu {
    min-width: 300px !important;
}

.nested-button > div {
    max-height: 250px;
    overflow-y: auto;
}

.nested-button > div div .input-group-text {
    padding: 0px !important;
    border: none !important;
}

.nested-button button::after {
    position: absolute;
    right: 11px;
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    margin-top: 12px;
    font-size: 16px;
    top: 6px;
}

#report-filter {
    background: white;
    color: #13249A;
    padding: 6px;
}

#report-filter::after {
    display: none !important;
}

.search-clear {
    position: absolute;
    right: 0;
    background: transparent;
    border: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10) !important;
}

.record_editable_till_datepicker {
    min-width: 250px !important;
}
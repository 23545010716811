.bg-body-tertiary {
    background-color: #FFFFFF !important;
    padding-left: 15px;
    padding-right: 15px;
    
}
.header-icon {
    margin-right: 10px;
    margin-top: -3px;
}
.nav-link {
    color: #55545c;
    
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}
.active-navbar {
    transition: border-bottom .5s ease-in-out;
}
.interviews-nav {
    margin-left: 30px;
}
@media only screen and (max-width: 600px) {
    /* .dropdown-menu.show{
        left: 0px !important;
    } */
    .interviews-nav{
        margin-left: 0px;
    }
    .bg-body-tertiary{
        padding-bottom: 10px;
    }
}

/* div[aria-labelledby="pofile-dropdown"] {
    left: -90px !important;
} */

.logo-img {
    width: 114px !important;
}

@media only screen and (min-width: 600px) {
    .logo-img {
        width: 114px !important;
    }
}
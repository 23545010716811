/* .footer-button{
    border: 2px solid #e9dfdf;
    background-color: #f9f9f9;
    color: black;
    padding: 9px 25px;
    border-radius: 22px;
    font-weight: bold;
}
.footer-button:hover{
    background-color: black;
    color: white;
}
.font-weight-bold {
    font-weight: bold;
}
.resume-link {
    color: blue;
    cursor: pointer;
}


table {
    width: 100%;
    margin-top: 20px;
    border: none;
    border-radius: 30px;
  }
  
  thead {
    border-radius: 30px;
  }
  
  th {
    background-color: #E5DEFF;
  }
  
  th, td {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: Roboto;
    color: #000;
    text-align: center;
    padding: 10px 13px;
    border: 1px solid #E5DEFF;
  } */

  .p-paginator .p-dropdown .p-dropdown-label {
    margin-top: -12px !important;
  }

  .custom-btn {
    background: #B5B2C8 !important;
    border: none !important;
    margin-right: 30px;
    margin-top: 30px;
    color: black;
  }
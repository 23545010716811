
.request-interviewer-users-container{
    background-color: #F4F4F4 !important;
    height: 100vh;
}
.request-interviewer-users-title{
    color: #14037F;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.request-interviewer-users-jobdetails{
    color: #000;
    
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-transform: uppercase;
}
.request-interviewer-users-feednback-completed{
    /* border: 1px solid #000000; */
    border-radius: 15px;
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.request-interviewer-users-feednback-completed>thead>tr>th{
    /* background-color: #E5DEFF !important; */
}
.request-interviewer-users-feednback-completed>tbody>tr>td{
    /* border-right: 1px solid #000000; */
}
.request-interviewer-users-feednback-completed>tbody>tr>td>.selected, .request-interviewer-users-feednback-completed>tbody>tr>td>.pass{
    color: #128200;
}
.request-interviewer-users-feednback-completed>tbody>tr>td>.rejected, .request-interviewer-users-feednback-completed>tbody>tr>td>.fail{
    color: #CE2222;
}
small.last-one-month{
    font-size: 15px;
    display: block;
    font-weight: bold;
    
}
.fromDateCol{
    padding-top: 25px;
    padding-bottom: 10px;
    width: 13%;
}
.toDateCol{
    width: 13%;
}
.request-interviewer-users-search-button, .request-interviewer-users-search-button:hover, .request-interviewer-users-search-button:active, .request-interviewer-users-search-button:focus, .request-interviewer-users-search-button:visited{
    border-radius: 16px;
    background: #2F2376;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
}
@media only screen and (max-width: 600px) {
    .request-interviewer-users-container>.row>.col-4{
        width: 100%;
        margin: 0px !important;
    }
    .request-interviewer-users-container>.row>.col-2{
        width: 50%;
        margin: 0px !important;
    }
    .request-interviewer-users-container>.row>.col-2.fromDateCol{
        padding-top: 0px !important;
    }
    .request-interviewer-users-feednback-row{
        margin-top: 10px;
    }
}

/* .resume-modal  */
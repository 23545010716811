.live-interviewer-container{
    background: #F4F4F4;
    height: 100vh;
}
.sample-feedback-button, .sample-feedback-button:hover, .sample-feedback-button:active, .sample-feedback-button:focus, .sample-feedback-button:visited{
    border-radius: 16px;
    background: #E7E8E9;
    color: #000;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    border: 0px solid transparent;
}
.sample-feedback-button>span{
    margin-left: 5px;
    margin-top: 5px;
}
.google-meet-id-p{
    color: #A7A7A7;
    
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 0px;
}
.google-meet-id-span{
    color: #000;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.live-interviewer-details-table{
    margin-bottom: 0px;
}
.live-interviewer-details-card{
    padding: 5px;
    margin-bottom: 5px;
}
.live-interviewer-details-card>.card-body{
    padding: 0px 10px;
}
.live-interviewer-details-card>.card-body>.row>.live-interviewer-details-col>.live-interviewer-details-card-title{
    color: #3b3b3b;
    
    font-size: .9rem;
    font-style: normal;
    
    line-height: 28px;
    padding: 0px;
    margin-bottom: 0px;
}
.live-interviewer-details-card>.card-body>.row>.live-interviewer-details-col>.live-interviewer-details-card-title-samll{
    color: #201e24;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.024px;
    padding: 0px 0px 5px 0px;
    margin-bottom: 0px;
}
.google-meet-link-button, .google-meet-link-button:hover, .google-meet-link-button:active, .google-meet-link-button:focus, .google-meet-link-button:visited{
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.google-meet-link-button>span{
    margin-left: 5px;
    margin-top: 5px;
}
.interviewStatus{
    width: 90%;
}
.live-interviewer-details-button, .live-interviewer-details-button:hover, .live-interviewer-details-button:active, .live-interviewer-details-button:focus, .live-interviewer-details-button:visited{
    
    line-height: 24px;
    letter-spacing: 0.024px;
    
}
.live-interviewer-sills-card>.card-body{
    padding: 0px;
}
.live-interviewer-sills-card>.card-body>.nav>.nav-item>button, .live-interviewer-sills-card>.card-body>.nav>.nav-item>button:hover, .live-interviewer-sills-card>.card-body>.nav>.nav-item>button:active, .live-interviewer-sills-card>.card-body>.nav>.nav-item>button:focus, .live-interviewer-sills-card>.card-body>.nav>.nav-item>button:visited{
    color: #201e24;
    
    font-size: .9rem;
    
    font-weight: bold !important;
    line-height: 24px;
    letter-spacing: 0.024px;
    border: 0px ;
}
.live-interviewer-sills-card>.card-body>.nav>.nav-item>button.active{
    color: #13249A;
    border-bottom: 2px solid #13249A;
    font-weight: bold;
    background-color: #edeffc;
}
.live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.live-interviewer-other-details-code-test-title{
    color: #000;
    
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    float: left;
    margin-bottom: 0px;
}
.live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.coding-test-switch{
    float: right;
}
.live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.coding-test-switch>.form-check-input:checked{
    background-color: #2F2376;
    border-color: #000000;
}
.live-interviewer-other-details>.col-6>.card>.card-body{
    padding: 10px;
}
.live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.coding-test-switch>.form-check-input{
    width: 60px;
    height: 30px;
}
.live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.live-interviewer-other-details-code-test-hint{
    color: #F00;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
}
.live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.nav>.nav-item>button, .live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.nav>.nav-item>button:hover, .live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.nav>.nav-item>button:active, .live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.nav>.nav-item>button:focus, .live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.nav>.nav-item>button:visited{
    color: #2F2376;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px
}
.live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.nav>.nav-item>button.active{
    border-radius: 8px;
    background: #1A0063;
    color: #FFFFFF;
}
.live-interviewer-other-details>.col-6>.card>.card-body>.row>.col-12>.nav{
    border-bottom: 0px solid transparent;
}
.live-interviewer-other-details-code-test-upload-screenshot{
    border-style: dashed;
    border-color: #a0a1a7;
}
.live-interviewer-other-details-code-test-upload-screenshot-hint{
    color: #666;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
    text-align: center;
}
.live-interviewer-container>.row>.col-12>.live-interviewer-container-tab>.col-1{
    padding: 0px;
    text-align: center;
    background: #FFFFFF;
    width: 60px;
    height: 100hv;
}
.live-interviewer-container>.row>.col-12>.live-interviewer-container-tab>.col-11{
    width: 97%;
}
.live-interviewer-container>.row>.col-12>.live-interviewer-container-tab>.col-1>.nav>.nav-item{
    border-bottom: 0.5px solid #e9e9e9;
    color: #ADAAAF;
}
.live-interviewer-container>.row>.col-12>.live-interviewer-container-tab>.col-1>.nav>.nav-item>.nav-link{
    padding: 20px 0px;
}
/* .live-interviewer-container>.row>.col-12>.live-interviewer-container-tab>.col-1>.nav>.nav-item>.active{
    background: #2F2376;
    color: #FFFFFF;
} */
.live-interviewer-resume-download{
    
    
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0.024px;
    text-decoration: none;
}
.live-interviewer-resume-download>span{
    margin-left: 10px;
}
.react-pdf__Document>.react-pdf__Page>.react-pdf__Page__canvas{
    margin: auto;
}
@media only screen and (max-width: 600px) {
    .live-interviewer-details-card>.card-body>.row>.col-xxs-6, .live-interviewer-details-card>.card-body>.row>.col-xs-6, .live-interviewer-details-card>.card-body>.row>.col-sm-6{
        width: 100%;
    }
    .sample-feedback-button{
        padding: 10px 5px;
    }
    .google-meet-link-button{
        padding: 10px 9px;
    }
    .live-interviewer-other-details>.col-6{
        width: 100%;
    }
    .live-interviewer-container>.row>.col-12>.live-interviewer-container-tab>.col-1{
        width: 60px;
    }
    .live-interviewer-container>.row>.col-12>.live-interviewer-container-tab>.col-11{
        width: 87%;
    }
}


#editor {
    height: 500px;
    float: left;
    border: 1px solid black;
  }
  #question {
    height: 500px;
    float: right;
    background-color: black;
    color: white;
  }
  #runCodeBtn {
    clear: both;
    display: block;
    margin-top: 10px;
    padding: 5px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }

  .tabHeight {
    height: 250px;
  }
.profile-settings-container{
    height: 100vh;
    background: #F2F1F5;
}
.profile-settings-title{
    color: #2F2F74;
    font-family: Roboto;
    font-size: 19.844px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin-left: 40px;
}
.profile-settings-title-col{
    padding: 15px 40px;
    border-bottom: 8px solid white;
}
.profile-settings-interviewer{
    color: #635151;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}
.profile-settings-interviewer-label{
    color: #2F2376;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
}
.profile-settings-interviewer-value{
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.profile-settings-image-div>.profile-settings-image{
    /* width: 215px; */
    width: 100%;
    border-radius: 50%;
    background: #FFF;
}
.profile-settings-image-div{
    background: linear-gradient(to right, #DD3232, #AF07FE);
    border-radius: 50%;
    width: 230px;
    padding: 7px;
    margin: auto;
}
@media only screen and (max-width: 600px) {
    .profile-settings-container-row>.col-3{
        width: 100%;
    }
    .profile-settings-container-row>.col-3>.card{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .profile-settings-container-row>.col-9{
        width: 100%;
        margin-top: 10px;
    }
}

@import "react-big-calendar/lib/css/react-big-calendar.css";
.calendar-container{
    background-color: #F4F4F4 !important;
    height: 100vh;
}
.calendar-title{
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.calendar-feednback-completed{
    border: 1px solid #000000;
    border-radius: 15px;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.calendar-feednback-completed>thead>tr>th{
    background-color: #E5DEFF !important;
}
.calendar-feednback-completed>tbody>tr>td{
    border-right: 1px solid #000000;
}
.rbc-event-content[title="Available"]{
    background-color: #b2cbfa;
    color: #023ba4;
    border-color: #b2cbfa;
   
    font-weight: 600;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
}
.rbc-day-slot .rbc-event[title~="Available"]{
    background-color: #b2cbfa;
    color: #023ba4;
    border-color: #b2cbfa;
    
    font-weight: 600;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
}
.rbc-event-content[title="Completed"]{
    background-color: #d5f9cf;
    color: #0d5c01;
    border-color: #d5f9cf;
    font-weight: 600;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
}
.rbc-day-slot .rbc-event[title~="Completed"]{
    background-color: #d5f9cf;
    color: #0d5c01;
    border-color: #d5f9cf;
    font-weight: 600;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
}
.rbc-event-content[title="Booked"]{
    background-color: #fcbcbc;
    color: #840202;
    border-color: #fcbcbc;
    font-weight: 600;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
}
.rbc-day-slot .rbc-event[title~="Booked"]{
    background-color: #fcbcbc;
    color: #840202;
    border-color: #fcbcbc;
    font-weight: 600;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
}
.rbc-event-content[title="booked"]{
    background-color: #fcbcbc;
    color: #840202;
    border-color: #fcbcbc;
    font-weight: 600;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
}
.rbc-day-slot .rbc-event[title~="booked"]{
    background-color: #fcbcbc;
    color: #840202;
    border-color: #fcbcbc;
    font-weight: 600;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
}
.rbc-month-row .rbc-row .rbc-event{
    background: none;
}
.calendar-feednback-completed>tbody>tr>td>.selected, .calendar-feednback-completed>tbody>tr>td>.pass{
    color: #128200;
}
.calendar-feednback-completed>tbody>tr>td>.rejected, .calendar-feednback-completed>tbody>tr>td>.fail{
    color: #CE2222;
}
.calendar-search-button, .calendar-search-button:hover, .calendar-search-button:active, .calendar-search-button:focus, .calendar-search-button:visited{
    border-radius: 16px;
    background: #2F2376;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
}
.calendar-feednback-row>.col-12>.card>.card-body>.rbc-calendar{
    height: 100vh !important;

}
@media only screen and (max-width: 600px) {
    .calendar-container>.row>.col-4{
        width: 100%;
        margin: 0px !important;
    }
    .calendar-container>.row>.col-2{
        width: 50%;
        margin: 0px !important;
    }
    .calendar-container>.row>.col-2.fromDateCol{
        padding-top: 0px !important;
    }
    .calendar-feednback-row{
        margin-top: 10px;
    }
}


.rbc-header {
    display: none !important;
}
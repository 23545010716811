.employer-dashboard-container{
    background-color: #F4F4F4 !important;
    /* height: 100vh; */
}
.employer-dashboard-title{
    color: #14037F;
    
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.employer-dashboard-title>.row>.col-12>.nav{
    background: #FFFFFF;
    border: 0px solid transparent !important;
    border-radius: 10px;
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.nav-link{
    background-color: none !important;
   
    
}

.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.nav-link>span{
    border-radius: 30px;
    border: 1px solid #000;
    background: #6BFF71;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20);
    color: #154E01;
    text-align: center;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.06px;
    padding: 2px 16px;
    margin-left: 10px;
}
.employer-dashboard-jobs-posted{
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.employer-dashboard-interview{
    background-color: #FFFFFF;
    border: 0px solid transparent;
    margin-top: 5px;
}
.employer-dashboard-interview-job-title{
    color: #000;
    
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 10px;
}
.employer-dashboard-interview-job-title>span{
    color: #9D99B6;
    
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}
.employer-dashboard-interview-name{
    color: #000;
    
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 0px;
}
.employer-dashboard-interview-skill{
    color: #000;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
}
.employer-dashboard-interview-company{
    color: #000;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
}
.employer-dashboard-interview-action{
    width: 50px;
    float: right;
}
.employer-dashboard-interview-action>.dropdown-toggle::after{
    display: none;
}
.employer-dashboard-interview-action-icon{
    color: #000000;
}
.employer-dashboard-interview-call{
    margin-right: 5px;
    margin-top: -3px;
}
.employer-dashboard-interview-join{
    margin-left: 5px;
   
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: -15px;
}
.employer-dashboard-interview-join-disbale, .employer-dashboard-interview-join-disbale:hover, .employer-dashboard-interview-join-disbale:active, .employer-dashboard-interview-join-disbale:focus{
    background: #DBDBDB;
    color: #8E8E8E;
    border: 0px solid transparent !important;
    border-radius: 20px;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: -15px;
}
.employer-dashboard-interview-join-video{
    color: #D65151;
}
.employer-dashboard-interview-join-video-disbale{
    color: #8E8E8E;
}
.employer-dashboard-interview-closed{
    border-radius: 16px;
    background: #DBDBDB;
    color: #8E8E8E;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.employer-dashboard-interview-on-hold{
    border-radius: 16px;
    background: #FF9900;
    color: black;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.employer-dashboard-interview-contact{
    border-radius: 18.068px;
    background: #6155A8;
    box-shadow: 0px 3.61361px 3.61361px 0px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
}
.employer-dashboard-interview-contact-disabled, .employer-dashboard-interview-contact-disabled:hover, .employer-dashboard-interview-contact-disabled:active, .employer-dashboard-interview-contact-disabled:focus{
    border-radius: 18.068px;
    border: 0px solid transparent;
    background: #DBDBDB;
    box-shadow: 0px 3.61361px 3.61361px 0px rgba(0, 0, 0, 0.25);
    color: #FBFBFB;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
}
.employer-dashboard-interview-time{
    color: #000;
    
    font-size: 14.454px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.682px;
    letter-spacing: 0.022px;
    margin-bottom: 0px;
}
.employer-dashboard-interview-today{
    color: #048109;
    
    font-size: 14.454px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.682px;
    letter-spacing: 0.022px;
    border-radius: 14.454px;
    background: #8AF29A;
    margin-bottom: 0px;
}
.employer-dashboard-feednback-completed{
    border: 1px solid #000000;
    border-radius: 15px;
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.employer-dashboard-feednback-completed>thead>tr>th{
    background-color: #E5DEFF !important;
}
.employer-dashboard-feednback-completed>tbody>tr>td{
    border-right: 1px solid #000000;
}
.employer-dashboard-feednback-completed>tbody>tr>td>a{
    text-decoration: none;
}
.employer-dashboard-feednback-completed>tbody>tr>td>.selected, .employer-dashboard-feednback-completed>tbody>tr>td>.pass{
    color: #128200;
}
.employer-dashboard-feednback-completed>tbody>tr>td>.rejected, .employer-dashboard-feednback-completed>tbody>tr>td>.fail{
    color: #CE2222;
}
.employer-dashboard-right{
    display: block;
}
.employer-dashboard-interview-join>span, .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-4>.row>.col-6>.employer-dashboard-interview-contact>span{
    margin-left: 10px;
}
.employer-dashboard-interview-card>.card-body>a{
    text-decoration: none;
}
.upload-profile-modal>.modal-dialog>.modal-content{
    background-color: #ddd !important;
}
.upload-profile-resume-cv{
    background-color: #FFFFFF;
    border:2px solid;
    border-style: dashed;
    padding: 43px 43px 0px 43px;
}
.uploadProfile, .maximum-25-profiled{
    width: 45%;
    float: inline-end;
}
.uploadCSV, .sample-upload-csv{
    width: 45%;
}
@media only screen and (max-width: 600px) {
    .employer-dashboard-left{
        padding: 7px;
    }
    .employer-dashboard-right{
        display: none;
    }
    .employer-dashboard-left-card, .employer-dashboard-left-card-active, .employer-dashboard-interview-card>.card-body, .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body{
        padding: 5px;
    }
    .employer-dashboard-left-card>.card-body, .employer-dashboard-left-card-active>.card-body{
        padding: 0px;
    }
    .employer-dashboard-left-menu-col{
        padding-right: 0px;
        padding-left: 5px;
    }
    .employer-dashboard-left-button>span, .employer-dashboard-interview-join>span{
        display: none;
    }
    .employer-dashboard-left-button>.employer-dashboard-left-calendar{
        float: none;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-10{
        width: 70%;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-2{
        width: 30%;
        padding-left: 0px;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-2>.employer-dashboard-interview-join{
        padding-left: 10px;
        padding-right: 10px;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-8{
        width: 100%;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-4{
        width: 100%;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-4>.row>.col-6{
        width: 100%;
        text-align: center !important;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-4>.row>.col-3{
        width: 50%;
        margin-top: 10px !important;
    }
}

.bar-dashboard {
    position: absolute;
    margin-left: -3.5rem !important;
    background: black;
    color: white;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 25px;
    transition: all .2s ease-in;
}
.bar-dashboard:hover{
    background-color: #14037F;
}
.job-filter {
    position: absolute;
    right: 0;
    margin-right: 18px !important;
    color: white;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
    cursor: pointer;
}

.timestamp p:nth-child(2)     {
    /* color: #efef03 !important; */
    color: #b7b733 !important;
    font-weight: bold !important;
}

.modal-content {
    min-height: 80vh !important;
}

.dropdown-menu {
    padding: 0px !important;
}

.button-disable {
    background: #B5B2C8 !important;
    border: none !important;
    pointer-events: none !important;
}

.hide {
    display: none !important;
}

.dialog-header, .dialog-content, .dialog-footer {
   
}

.dialog-root {
    
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
}

.dialog-header {
    border-top-left-radius: 14px !important;
    border-top-right-radius: 14px !important;
}

.dialog-footer {
    text-align: center !important;
    border-bottom-left-radius: 14px !important;
    border-bottom-right-radius: 14px !important;
}

.dialog-footer-buttons, .dialog-footer-buttons:hover, .dialog-footer-buttons:active, .dialog-footer-buttons:focus  {
    border-radius: 15px !important;
    padding: 6px 16px !important;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30) !important;
    background: #FFFBFE !important;
    color: black !important;
    border: none !important;
}

.job-container {
    margin-left: 0rem !important; /* Bootstrap's mx-5 translates to 3rem */
    margin-right: 0rem !important; /* Bootstrap's mx-5 translates to 3rem */
}

@media (min-width: 768px) {
    .job-container {
        margin-left: 3rem !important; /* Bootstrap's mx-5 translates to 3rem */
        margin-right: 3rem !important; /* Bootstrap's mx-5 translates to 3rem */
    }
}

.request-for-interviewer {
    text-align: right;
}

@media (max-width: 768px) {
    .request-for-interviewer {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .dashboard-jobs {
        padding: 0px !important;
    }
}

@media (max-width: 768px) {
    .dashboard-jobs {
        padding: 0px !important;
    }
}

@media (max-width: 768px) {
    .bar-dashboard {
        position: relative;
        margin-left: 0px !important;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
        width: 40px !important;
    }
    .job-filter {
        position: absolute;
        margin-left: 0px !important;
        margin-bottom: 10px !important;
        margin-top: -60px !important;
        width: 40px !important;
        right: 0;
    }
}

.load-more-container {
    display: flex;
    justify-content: center;
}

.bounce-animation {
    animation: bounce 2s infinite;
    bottom: 20px;
    position: fixed;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

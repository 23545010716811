.profile-image{
    width: 100px;
    border-radius: 50%;
    height: 100px;
    border: 2px solid #ced4da;
}
.interviewer-profile-details-left{
    color: #000;
    
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; 
}
.interviewer-profile-details-label{
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #201e24;
    font-size: .9rem;
    opacity: .7;
}
.interviewer-profile-details-value{
    color: #201e24;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.interviewer-profile-details-right-name{
    color: #000;
    
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; 
}
.interviewer-profile-details-right-location{
    color: #000;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.014px;
}
.interviewer-profile-details-right-designation{
    color: #000;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
}
.interviewer-profile-details-right-label{
    color: #3b3b3b;
    font-weight: normal !important;
    font-size: .9rem;
    font-style: normal;
    
    line-height: 28px; 
}
.interviewer-profile-details-right-value{
    color: #201e24;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
}
.interviewer-profile-details-right-bank-label{
    color: #3b3b3b;
    font-weight: normal !important;
    font-size: .9rem;
    font-style: normal;
    line-height: 28px;
}
.interviewer-profile-details-right-bank-value{
    color: #201e24;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 0px;
}
.FaRegEdit{
    margin-left: 10px;
}
.interviewer-profile-accordion>.accordion-item{
    /* border: 1px solid #A69FA1;
    margin-bottom: 30px;
    border-radius: 0px; */
}
.interviewer-profile-accordion>.accordion-item>.accordion-header>.accordion-button{
    padding: 8px 15px;
}
.location_preferences_right{
    float: right;
}
.interviewer-profile-accordion>.accordion-item>.accordion-collapse>.accordion-body>ol{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
}
.work_experience_details>.work_experience_details_title{
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0px;
}
.work_experience_details>.work_experience_details_company_name{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
}
.work_experience_details>.work_experience_details_start_and_end_time{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
    color: gray;
}
.work_experience_details>.work_experience_details_company_location{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
    color: gray;
}
.work_experience_details>.work_experience_details_employment_type{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
    color: gray;
}
.work_experience_details>.work_experience_details_employment_type>.employment_type{
    color: #000000;
}
.education_details>.education_school_name{
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0px;
}
.education_details>.education_school_location{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
    color: gray;
}
.education_details>.education_school_start_and_end_time{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
    color: gray;
}
.education_details>.education_school_grade{
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0px;
}
.download_certificate{
    margin-left: 10px;
}
/* .interviewer-profile-details-button{
    border-radius: 10px;
    border: 1px solid #FFF;
    background: #0024A3;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20);
    color: #FFF;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
    padding: 5px;
    text-decoration: none;
} */
.employer-dashboard-container{
    background-color: #F4F4F4 !important;
    /* height: 100vh; */
}
.employer-dashboard-title{
    color: #14037F;
    
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.employer-dashboard-title>.row>.col-12>.nav{
    background: #FFFFFF;
    border: 0px solid transparent !important;
    border-radius: 10px;
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.nav-link{
    background-color: none !important;
    
    
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.active{
    
    border-radius: 0px !important;
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.nav-link>span{
    border-radius: 30px;
    border: 1px solid #000;
    background: #6BFF71;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20);
    color: #154E01;
    text-align: center;
    
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.06px;
    padding: 2px 16px;
    margin-left: 10px;
}
.employer-dashboard-jobs-posted{
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: #212529;
    text-transform: uppercase;
}
.employer-dashboard-interview{
    background-color: #FFFFFF;
    border: 0px solid transparent;
    margin-top: 5px;
}
.employer-dashboard-interview-job-title{
    
    color: #646464;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 10px;
}
.employer-dashboard-interview-job-title>span{
    color: #000;
    
    font-size: 17px;
    
    font-weight: 800;
    line-height: 28px;
}
.employer-dashboard-interview-name{
    color: #000;
    
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 0px;
}
.employer-dashboard-interview-skill{
    color: #000;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
}
.employer-dashboard-interview-company{
    color: #000;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
}
.employer-dashboard-interview-action{
    width: 50px;
    float: right;
}
.employer-dashboard-interview-action>.dropdown-toggle::after{
    display: none;
}
.employer-dashboard-interview-action-icon{
    color: #000000;
}
.employer-dashboard-interview-call{
    margin-right: 5px;
}
.employer-dashboard-interview-join{
    margin-left: 5px;
   
    
    
    color: #FFFFFF;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: -15px;
}
.employer-dashboard-interview-join-disbale, .employer-dashboard-interview-join-disbale:hover, .employer-dashboard-interview-join-disbale:active, .employer-dashboard-interview-join-disbale:focus{
    background: #DBDBDB;
    color: #8E8E8E;
    border: 0px solid transparent !important;
    border-radius: 20px;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: -15px;
}
.employer-dashboard-interview-join-video{
    color: #D65151;
}
.employer-dashboard-interview-join-video-disbale{
    color: #8E8E8E;
}
.employer-dashboard-interview-closed{
    border-radius: 16px;
    background: #DBDBDB;
    color: #8E8E8E;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.employer-dashboard-interview-on-hold{
    border-radius: 16px;
    background: #DBDBDB;
    color: #FF9900;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.employer-dashboard-interview-contact{
    border-radius: 18.068px;
    background: #6155A8;
    box-shadow: 0px 3.61361px 3.61361px 0px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
}
.employer-dashboard-interview-contact-disabled, .employer-dashboard-interview-contact-disabled:hover, .employer-dashboard-interview-contact-disabled:active, .employer-dashboard-interview-contact-disabled:focus{
    border-radius: 18.068px;
    border: 0px solid transparent;
    background: #DBDBDB;
    box-shadow: 0px 3.61361px 3.61361px 0px rgba(0, 0, 0, 0.25);
    color: #FBFBFB;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
}
.employer-dashboard-interview-time{
    color: #000;
    
    font-size: 14.454px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.682px;
    letter-spacing: 0.022px;
    margin-bottom: 0px;
}
.employer-dashboard-interview-today{
    color: #048109;
    
    font-size: 14.454px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.682px;
    letter-spacing: 0.022px;
    border-radius: 14.454px;
    background: #8AF29A;
    margin-bottom: 0px;
}
.employer-dashboard-feednback-completed{
    border: 1px solid #000000;
    border-radius: 15px;
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
}
.employer-dashboard-feednback-completed>thead>tr>th{
    background-color: #E5DEFF !important;
}
.employer-dashboard-feednback-completed>tbody>tr>td{
    border-right: 1px solid #000000;
}
.employer-dashboard-feednback-completed>tbody>tr>td>a{
    text-decoration: none;
}
.employer-dashboard-feednback-completed>tbody>tr>td>.selected, .employer-dashboard-feednback-completed>tbody>tr>td>.pass{
    color: #128200;
}
.employer-dashboard-feednback-completed>tbody>tr>td>.rejected, .employer-dashboard-feednback-completed>tbody>tr>td>.fail{
    color: #CE2222;
}
.employer-dashboard-right{
    display: block;
}
.employer-dashboard-interview-join>span, .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-4>.row>.col-6>.employer-dashboard-interview-contact>span{
    margin-left: 10px;
}
.employer-dashboard-interview-card>.card-body>a{
    text-decoration: none;
}
.upload-profile-modal>.modal-dialog>.modal-content{
    background-color: #ddd !important;
}
.upload-profile-resume-cv{
    background-color: #FFFFFF;
    border:2px solid;
    border-style: dashed;
    padding: 10px 10px 0px 10px;
}
.uploadProfile, .maximum-25-profiled{
    width: 45%;
    float: inline-end;
}
.uploadCSV, .sample-upload-csv{
    width: 45%;
}
@media only screen and (max-width: 600px) {
    .employer-dashboard-left{
        padding: 7px;
    }
    .employer-dashboard-right{
        display: none;
    }
    .employer-dashboard-left-card, .employer-dashboard-left-card-active, .employer-dashboard-interview-card>.card-body, .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body{
        padding: 5px;
    }
    .employer-dashboard-left-card>.card-body, .employer-dashboard-left-card-active>.card-body{
        padding: 0px;
    }
    .employer-dashboard-left-menu-col{
        padding-right: 0px;
        padding-left: 5px;
    }
    .employer-dashboard-left-button>span, .employer-dashboard-interview-join>span{
        display: none;
    }
    .employer-dashboard-left-button>.employer-dashboard-left-calendar{
        float: none;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-10{
        width: 70%;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-2{
        width: 30%;
        padding-left: 0px;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-2>.employer-dashboard-interview-join{
        padding-left: 10px;
        padding-right: 10px;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-8{
        width: 100%;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-4{
        width: 100%;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-4>.row>.col-6{
        width: 100%;
        text-align: center !important;
    }
    .employer-dashboard-interview-card>.card-body>.employer-dashboard-interview>.card-body>.row>.col-4>.row>.col-3{
        width: 50%;
        margin-top: 10px !important;
    }
}


.p-carousel-indicators {
    display: none !important;
}
 $primary: #13249A;
 $secondary: #FEDA14;



 //font-family
 @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
 $font-family-sans-serif:"Manrope", sans-serif;
 $font-size-base: .9rem;


 //padding
 $alert-padding-y: .6rem;


 //card
 $card-border-color: #ebebeb;


 //Input
 $input-font-weight: 600;

 //button
 $btn-font-weight:800;

@import '../node_modules/bootstrap/scss/bootstrap.scss';

body, html{
  height: 100%;
  background: #F4F4F4 ;
}
body{
  font-weight: 500;
}
.fixed-padding{
  padding-top: 124px;
}
.fixed-padding-2{
  padding-top: 64px;
}
.fixed-padding-3{
  padding-top: 222px;
}
label{
  
}
#root{
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.logo{
  img{
    width: 160px;
  }
}
.dynamic_hiring{
  background: url('./Assets/dynamic_hiring.png') center center no-repeat;
  background-size: cover;
}
.css-13cymwt-control{
  border-color: #dee2e6 !important;
}
.page-title{
  font-size: 16px !important;
}
.css-t3ipsp-control:hover{
  // color: var(--bs-body-color);
  //   background-color: $primary;
  //   border-color: #8992cd !important;
  //   outline: 0;
  //   box-shadow: 0 0 0 0.25rem rgba(19, 36, 154, 0.25);
}
.react-tags{
  border-width: 1px !important;
  border-color: #dee2e6 !important;
  font-size: .9rem;
}
.css-t3ipsp-control{
  border-color: #8992cd !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(19, 36, 154, 0.25) !important;
}
.css-1nmdiq5-menu div{
  // background-color: #e8e8ea;
}
.react-tags.is-active {
  border-color: #8992cd !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(19, 36, 154, 0.25) !important;
}
.react-tags__tag:hover{
  background-color: var(--bs-body-color) !important;
}
.react-tags__tag{
  font-size: .9rem !important;
  background: $primary !important;
  color: #FFFFFF;
}
.react-tags__list-item .react-tags__tag::after{
  background-color: #FFFFFF !important;
}
.react-tags__listbox-option:not([aria-disabled='true']).is-active{
  background-color: $primary !important;
}
.react-tags__combobox{
  font-size: .9rem !important;
}
.active-navbar {
  color: $primary !important;
}
.card:hover {
  // transform: translateY(-5px);
  transition-timing-function: ease-in;
  transition: .3s;
  box-shadow: 0 7px 10px #dbdae128, 0 22px 10px 2px #dbdae128, 0 8px 10px #dbdae128 !important;
}
.dashboard-left-card-active{
  border: 1px solid rgba($primary, .6);
  box-shadow: 0 7px 10px #dbdae128, 0 22px 10px 2px #dbdae128, 0 8px 10px #dbdae128 !important;
  background-color: rgba($primary, .1);
}
.dashboard-left-card-active .dashboard-left{
  background-color: rgba($primary, .5);
}
.dashboard-left-card-active .dashboard-left-menu-title{
  color: $primary;
  font-weight: bold;
}
.dashboard-left-card-active .dashboard-left-menu-count{
  color: $primary;
  font-weight: bold;
}
.dashboard-left-icon{
  color: $primary;
}
.table{
  border-collapse: separate;
    border-spacing: 0 .2rem;
}
.table th{
  font-size: .81rem;
  color: $primary;
  background-color: #e7e9f5;
}
.table tr th:first-child{
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.table tr th:last-child{
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.table tr td:first-child{
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.table tr td:last-child{
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.table tr td{
  font-size: .84rem;
  color: #201e24;
  line-height: .84rem;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
}
.form-label{
  font-size: .9rem;
  color: #201e24;
  opacity: .7;
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.active{
  border-bottom: 1px solid $primary !important;
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.active{
  border-bottom: 1px solid $primary !important;
  border-radius: 0px !important;
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.active{
  border-bottom: 1px solid $primary !important;
  border-radius: 0px !important;
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.active{
  border-bottom: 1px solid $primary !important;
  border-radius: 0px !important;
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.active{
  border-bottom: 1px solid $primary !important;
  border-radius: 0px !important;
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.active{
  border-bottom: 1px solid $primary !important;
  border-radius: 0px !important;
}
.employer-dashboard-title>.row>.col-12>.nav>.nav-item>.active{
  border-bottom: 1px solid $primary !important;
  border-radius: 0px !important;
}
.acc-edit{
  position: absolute;
  right: 3rem;
}
.search-wrapper .chip{
  background: $primary !important;
  border-radius: 4px !important;
}
.modal-title{
  font-size: 1rem;
  font-weight: 600;
}
.dashboard-interview-card .card-body .cursor-pointer{
  margin-bottom: 16px;
}
.dashboard-interview-card .card-body .cursor-pointer:hover{
  // transform: translateY(-5px);
  transition-timing-function: ease-in;
  transition: .3s;
  box-shadow: 0 7px 6px #e9e9ea28, 0 8px 6px 2px #e9e9ea28, 0 8px 6px #e9e9ea28 !important;
}
.dashboard-interview-card.card:hover{
  transform: none !important;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active{
  background-color: $primary !important;
  color: #ebebeb !important;
}
.rbc-toolbar button{
  font-size: .9rem !important;
}
.rbc-toolbar-label{
  font-weight: bold;
  font-size: 1rem;
}
.rbc-off-range-bg {
  background: #f6f5f9 !important;
}

//Scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9;
}
.react-datepicker__month-container{
    font-family: $font-family-sans-serif;
}
.react-datepicker__input-container div:first-child{
  width: 100%;
}
.live-interviewer-container>.row>.col-12>.live-interviewer-container-tab>.col-1>.nav>.nav-item>.active{
  background: $primary;
  color: #FFFFFF;
}
.live-interviewer-sills-card>.card-body>.nav>.nav-item>button:hover{
  border-color: #FFFFFF;
  color: $primary !important;
}
.live-interviewer-sills-card>.card-body>.nav>.nav-item>button.active{
  color: $primary !important;
  
  border-bottom: 2px solid $primary !important;
}
.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link{
  color: #201e24;
  font-size: .9rem;
  font-weight: bold !important;
  line-height: 24px;
  letter-spacing: 0.024px;
  border-bottom: 1px solid #a9a9a9 !important;
  }
.nav-underline .nav-link.active, .nav-underline .show > .nav-link{
  border-color: $primary !important;
  color: $primary !important;
}
.sec-menu{
  position: fixed;
  width: 80px;
  height: 100%;
}
.sec-menu-padding{
  padding-left: 80px;
}
.prim-menu{
  position: fixed;
  width: 16.66666667%;
  height: 100%;
}
.prim-menu-padding{
  padding-left: 16.66666667%;
}
.nav-tabs .nav-link:hover{
  border-top-color: #FFFFFF;
  border-left-color: #FFFFFF;
  border-right-color: #FFFFFF;
  border-bottom: 2px solid $primary;
}
.nav-tabs .nav-link.active{
  border-bottom: 2px solid $primary !important;
  border-top-color: #FFFFFF;
  border-left-color: #FFFFFF;
  border-right-color: #FFFFFF;
  color: $primary !important;
  background-color: #edeffc;
}
.nav-underline.nav-justified{
  gap: 0 !important;
}

.profile{
  display: flex;
  position: fixed;
  right: 20px;
  top: 10px;
}
@media only screen and (max-width: 1400px) {
  .profile{
    position: fixed;
    right: 88px;
    top: 10px;
  }
}
.css-qbdosj-Input{
  font-weight: 600 !important;
}
.dashboard-interview-today{
  background-color: rgba($primary, 0.2);
}
.dropdown-menu .dropdown-item{
  font-weight: 600;
}
.dropdown-menu .dropdown-item:first-child{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.dropdown-menu .dropdown-item:last-child{
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dropdown-menu .dropdown-item:hover{
  background-color: rgba($primary, .1);
}
.live-interviewer-sills-card>.card-body>.nav>.nav-item>button:hover{
  border-bottom: 2px solid $primary !important;
}
.nav-justified .nav-item .nav-link:hover{
  border-bottom: 2px solid $primary !important;
  color: $primary !important;
}
.nav-underline.nav-justified li.nav-item .nav-link.active{
  border-bottom: 2px solid $primary !important;
  color: $primary !important;
  background-color: #edeffc !important;
}
.react-pdf__Page__canvas{
  margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}
.icon-wrap {
  width: 80px;
  height: 80px;
  position: relative;
  
}
.tilt {
  transition: all 0.2s ease-in;
}
.icon-wrap img {
  position: absolute;
  z-index: 2;
  left: 50%;
  margin-left: -22px;
  top: 20px;
}
.icon-wrap::after {
  width: 36px;
  height: 36px;
  content: "";
  display: block;
  background-color: #18203f;
  transform: rotate(45deg);
  position: absolute;
  right: 4px;
  bottom: 8px;
  z-index: 1;
}
.text_highlite {
  color: #a2aac7;
}
.text_highlite_t {
  color: #6D9FE7;
}
.tilt:hover {
  transform: translateY(-10px);
}
.tilt:hover img {
  transform: rotate(20deg);
}
.testimonials{

}
.carousel-bullets{
  display: flex;
  gap: 8px;
}
.carousel-bullets .bullet{
  width: 10px;
  height: 10px;
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
}
.carousel-bullets .bullet.active{
  width: 16px;
  background-color: #FEDA14;
}
.pageTitle_fixed{
  position: fixed;
    left: 0;
    right: 0;
    background: #f4f4f4;
    z-index: 1;
    top: 52px;
    width: 100%;
}
.card-body-scroll{
  max-height: 600px;
  overflow-y: auto;
}
.table-responsive{
  max-height: 600px;
}
.INTERVIEW_FINAL_STATUS_REJECTED{
  background-color: #fcbcbc;
  color: #840202;
  font-weight: bold;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
}
.INTERVIEW_FINAL_STATUS_SELECTED{
  background-color: #d5f9cf;
  color: #0d5c01;
  font-weight: bold;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
}
.table > thead.sticky-top{
  z-index: 0 !important;
}
.tab-adjust .tab-content{
  padding-right: 20px;
  max-height: 372px;
  overflow-y: auto;
}
.live-interviewer-sills-card .tab-content{
  padding-right: 20px;
  max-height: 372px;
  overflow-y: auto;
}
.modal-scroll{
  max-height: 300px;
  overflow-y: auto;
}
.tab-adjust_2 .tab-content{
  padding-right: 20px;
  max-height: 256px;
  overflow-y: auto;
}
@media only screen and (max-width: 968px) {
  .mobile-scrl{
    position: static;
  }
  .mobile-scrl-head{
    padding-top: 54px;
  }
  .live-interviewer-sills-card .nav-tabs{
    overflow-x: auto;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .hist-padding{
    padding-top: 292px;
  }
  .hist-padding{
    padding-top: 292px;
  }
  .live-interviewer-sills-card .nav-tabs li{
    width: 200px !important;
    height: 64px;
  }
  .live-interviewer-sills-card .nav-tabs li button{
    height: 64px;
  }
  .live-interviewer-other-details .nav-tabs{
    overflow-x: auto;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .live-interviewer-other-details .nav-tabs li{
    width: 200px !important;
    height: 64px;
  }
  .live-interviewer-other-details .nav-tabs li button{
    height: 64px;
  }
  .live-interviewer-other-details .nav-underline.nav-justified {
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 30px !important;
    white-space: nowrap;
  }
  .live-interviewer-other-details .nav-underline.nav-justified li{
    width: 200px !important;
    height: 94px;
  }
  .live-interviewer-other-details .nav-underline.nav-justified li button{
    height: 94px;
  }
  .jd_sche{
    position: absolute;
    top: 10px;
  }
  .fixed-padding-3 {
    padding-top: 354px;
}
.payment_mob{
  padding-top: 212px;
}
.settings_mob{
  padding-top: 160px;
}
}

.brd-0{
  border-radius: 0 !important;
}
.split_theme .p-button-text{
  color: #13249A;
}
.p-menu-overlay .p-menu-list{
  padding-left: 0px;
  margin-bottom: 0px;
}
.p-menu-overlay .p-menu-list .p-menuitem-link{
  text-decoration: none;
  font-weight: 600;
  padding-top: 5px;
    padding-bottom: 5px;
    color: #212529 !important;
}

.p-menu-overlay .p-menu-list .p-menuitem-link:hover {
  background-color: rgba(19, 36, 154, 0.1);
}
.inner_title{
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #212529;
}
.tooltip{
  position: fixed !important;
}
.react-calendar{
  font-family: "Manrope", sans-serif !important;
  width: 100% !important;
  border: 1px solid #f8f9fa !important;
  border-radius: 8px;
}
.react-calendar__tile--hasActive{
  background-color: #13249A !important;
  color: #FFFFFF !important;
}
.customCalenderClass .react-calendar__tile--hasActive{
  background-color: #13249A !important;
  color: #FFFFFF !important;
}
.customCalenderClass .react-calendar__tile--hasActive{
  background-color: #13249A !important;
}
.date_filter{
  position: fixed;
  top: 115px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background-color: #f4f4f4;
  padding-left: 22px;
  padding-right: 22px;
}
.reporTable .table-responsive{
  max-height: 510px;
}
.payment_rep .table-responsive{
  max-height: 510px;
}
.approved{
  display: inline-block;
  background-color: #d5f9cf;
    color: #0d5c01;
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
}
.rejected{
  display: inline-block;
  background-color: #fcbcbc;
    color: #840202;
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
}
.selected{
  display: inline-block;
  background-color: #d5f9cf;
    color: #0d5c01;
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
}
.p-component{
  font-family: "Manrope", sans-serif;
}
.input_grp_buttons  .btn-outline-primary{
  border-radius: 0px;
  border-right: 0px;
}

.input_grp_buttons  .btn-outline-primary:first-child{
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}
.input_grp_buttons .btn-outline-primary:last-child{
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
  border-right: 1px solid #13249A !important;
}
.form-control.designtn{
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}
.p-dropdown-items{
  padding-left: 0px;
}
.react-datepicker-popper {
  z-index: 3;
}
.closed-job{
  background-color: #FF9F76 !important;
  border: 1px solid #C35D31 !important;
}
.onHold-job{
  background-color: #9CCBF8 !important;
  border: 1px solid #316CC3 !important;
}
.list-job{
  background-color: #E8AFFF !important;
  border: 1px solid #B547E0 !important;
}
.css-1u9des2-indicatorSeparator{
  display: none;
}
.css-13cymwt-control{
  border-radius: .375rem !important;
}
.css-1xc3v61-indicatorContainer{
  padding: 7px 8px !important;
  color: #343a40 !important;
}
.css-13cymwt-control{
  min-height: 36px !important;
}
.css-t3ipsp-control{
  min-height: 36px !important;
}
.css-tj5bde-Svg{
  width: 17px;
}
.p-confirm-dialog-reject{
  color: #13249A !important;
  font-weight: bold;
  border: 1px solid #13249A;
  border-radius: 0.375rem;
  padding-top: .3rem;
    padding-bottom: .3rem;
    margin-right: .5rem;
}
.p-confirm-dialog-reject:hover{
  background-color: #13249A;
  color: #FFFFFF !important;
}

.p-confirm-dialog-accept{
  color: #FFFFFF !important;
  font-weight: bold;
  border: 1px solid #13249A;
  border-radius: 0.375rem;
  padding-top: .3rem;
    padding-bottom: .3rem;
    background-color: #FFFFFF;
    background-color: #13249A;
    
}
.employer-dashboard-interview-contact.alljob{
  color: #13249A !important;
  font-weight: bold !important;
  border: 1px solid #13249A !important;
  border-radius: 0.375rem !important;
  background: #FFFFFF !important;
  box-shadow: none !important;
}
.employer-dashboard-interview-contact.alljob.button-disable{
  opacity: .6;
}
.employer-dashboard-interview-contact.alljob svg{
  margin-right: 12px;
}
.employer-dashboard-interview-contact.alljob:hover{
  background-color: #13249A !important;
  color: #FFFFFF !important;
}
.p-confirm-dialog-accept:hover{
  background-color: #0b1973;
  color: #FFFFFF !important;
}
.p-confirm-dialog-accept:focus{
  box-shadow: none;
}
.employer-dashboard-interview-today.closed-job{
  background-color: #FF9F76 !important;
    border: 1px solid #C35D31 !important;
    color: #5f240a  !important;
}
.employer-dashboard-interview-on-hold.holdjob{
  background-color: #9CCBF8 !important;
    border: 1px solid #316CC3 !important;
    color: #0f274a  !important;
}
.employer-dashboard-interview-closed.closed-job{
  background-color: #FF9F76 !important;
  border: 1px solid #C35D31 !important;
  color: #5f240a  !important;
}
.dropdown-menu .dropdown-item:hover{
  background-color: #deebff !important;
}
.mobile-scrl{
  position: static;
}
.mobile-scrl-head{
  padding-top: 54px;
}
.form-select option:hover{
  background-color: #198754;
  color: #fff;
}
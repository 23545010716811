.create-job-container{
    background-color: #F4F4F4 !important;
    height: 100vh;
}
.create-job-col-title{
    background-color: #FFFFFF !important;
}
.react-datepicker-wrapper{
    width: 100%;
}
.ck-editor__editable_inline {
    min-height: 200px;
}
.create-job-form-submit-button, .create-job-form-submit-button:hover, .create-job-form-submit-button:active, .create-job-form-submit-button:focus, .create-job-form-submit-button:visited{
    border-radius: 10px;
    background: #2F2F74;
    color: #FFFFFF;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.create-job-form-cancel-button, .create-job-form-cancel-button:hover, .create-job-form-cancel-button:active, .create-job-form-cancel-button:focus, .create-job-form-cancel-button:visited{
    border-radius: 10px;
    background: white;
    color: #000;   
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-right: 10px;
}
.pl-0{
    padding-left: 0px !important;
}

.confirm-model .modal-content {
    width: 350px;
    margin: 0px;
    background: #D5D5D5 !important;
    min-height: auto !important
}

.confirm-action-button {
    border-radius: 15px !important;
    padding: 6px 16px !important;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30) !important;
    background: #FFFBFE !important;
    color: black !important;
    border: none !important;
}















Calendar.css
.customCalenderClass {
    width: 100%;
    max-width: 250px;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1em;
}

.customCalenderClass .react-calendar__tile {
    height: 1.2em;
    padding: 1px;
    margin: 0;
    font-size: 0.8em;
}

.customCalenderClass .react-calendar__year-view__months__month,
.customCalenderClass .react-calendar__decade-view__years__year,
.customCalenderClass .react-calendar__century-view__decades__decade {
    height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    margin: 0;
    font-size: 0.8em;
}

.customCalenderClass .react-calendar__navigation {
    margin-bottom: 1px;
}

.customCalenderClass .react-calendar__navigation button {
    min-width: 25px;
    background: none;
    font-size: 0.8em;
    padding: 1px;
}

.customCalenderClass .react-calendar__navigation button:enabled:hover,
.customCalenderClass .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.customCalenderClass .react-calendar__tile:enabled:hover,
.customCalenderClass .react-calendar__tile:enabled:focus {
    background: #e6e6e6;
    color: black;
    border-radius: 2px;
}

.customCalenderClass .react-calendar__tile--now {
    background: transparent;
    border-radius: 2px;
    font-weight: bold;
    color: black;
}

.customCalenderClass .react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff !important;
    color: white;
}

.customCalenderClass .react-calendar__tile--active {
    background: #006edc !important;
    border-radius: 2px;
    font-weight: bold;
    color: white;
}

.customCalenderClass .react-calendar__tile--active:enabled:hover,
.customCalenderClass .react-calendar__tile--active:enabled:focus {
    background: #1087ff !important;
    color: white;
}

.customCalenderClass .react-calendar__tile--hasActive {
    background: #13249A !important;
    color: white;
}
.logn-tab-active-button,
.logn-tab-active-button:hover,
.logn-tab-active-button:active,
.logn-tab-active-button:focus,
.logn-tab-active-button:visited {
  border: 1px solid #f0e9ff;
  border-radius: 4px;
  background: #f0e9ff;
  color: #2f2f74;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  padding: 7px 12px 7px 12px;
}
.logn-tab-inactive-button,
.logn-tab-inactive-button:hover,
.logn-tab-inactive-button:active,
.logn-tab-inactive-button:focus,
.logn-tab-inactive-button:visited {
  border: 1px solid #ffffff;
  border-radius: 4px;
  background: #ffffff;
  color: #2f2f74;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  padding: 7px 12px 7px 12px;
}
a.forget-password {
  text-decoration: none;
}
.login-form-submit-button,
.login-form-submit-button:hover,
.login-form-submit-button:active,
.login-form-submit-button:focus,
.login-form-submit-button:visited {
  width: 100%;
  border-radius: 10px;
  background: #2f2f74;
  color: #ffffff;
  text-align: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.dontHaveAnAccount {
  color: #0e0e0e;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.signUpAs {
  color: #2f2f74;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.create-account-link {
  text-decoration: none;
  color: #13249A;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.5px;
  display: inline-block;
  transition: all .2s ease-in;
}

.create-account-link:hover .create-account-icon{
  background: #13249A;
}
.create-account-link:hover .create-account-icon svg{
  color: #ffffff;
}
.create-account-link > .create-account-icon {
  border-radius: 6px;
  
  background: #efefef;
  padding: 26px 25.525px 26px 25.725px;
  width: 75px;
  margin: auto;
  margin-bottom: 8px;
  transition: all .2s ease-in;

}
.mobileNumberDiv {
  padding-right: 0px;
}
.sendOTP {
  padding-left: 0px;
}
.sendOTP > .logn-tab-active-button,
.sendOTP > .logn-tab-active-button:hover,
.sendOTP > .logn-tab-active-button:active,
.sendOTP > .logn-tab-active-button:focus,
.sendOTP > .logn-tab-active-button:visited {
  border: 1px solid #f0e9ff;
  border-radius: 4px;
  background: #f0e9ff;
  color: #2f2f74;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  padding: 7px 20px 7px 20px;
}
.otp {
  margin-left: 15px;
}
.resendOTP {
  color: #2f2f74;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  .logn-tab-active-button,
  .logn-tab-active-button:hover,
  .logn-tab-active-button:active,
  .logn-tab-active-button:focus,
  .logn-tab-active-button:visited {
    padding: 7px 20px 7px 20px;
  }
}

.top-input {
  flex: 1 !important;
}

.rating .star {
    font-size: 32px;
    color: #ddd;
    margin: 0 5px;
    cursor: pointer;
    cursor: "pointer";
    margin: "0 5px";
}

.rating .star.selected {
    color: #ffc107;
}
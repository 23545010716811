.sign-up-form-submit-button, .sign-up-form-submit-button:hover, .sign-up-form-submit-button:active, .sign-up-form-submit-button:focus, .sign-up-form-submit-button:visited{
    width: 100%;
    
    
    color: #FFFFFF;
    text-align: center;
    
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.alreadyHaveAnAccount{
    color: #0e0e0e;
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.terms-conditions{
    color: #007BFF;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
    text-decoration: none;
}
.login{
    color: #2F2F74;
    text-align: center;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.014px;
    text-decoration: none;
}
.page-title{
    color: #0e0e0e;
    
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}
.enterVerificationCode{
    color: #2F2F74;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; 
}
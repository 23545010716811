
.history-container{
    background-color: #F4F4F4 !important;
    height: 100vh;
}
.history-title{
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
}
/* .history-feednback-completed{
    border: 1px solid #000000;
    border-radius: 15px;
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
} */
/* .history-feednback-completed>thead>tr>th{
    background-color: #E5DEFF !important;
}
.history-feednback-completed>tbody>tr>td{
    border-right: 1px solid #000000;
} */
.history-feednback-completed>tbody>tr>td>.selected, .history-feednback-completed>tbody>tr>td>.pass{
    color: #128200;
}
.history-feednback-completed>tbody>tr>td>.rejected, .history-feednback-completed>tbody>tr>td>.fail{
    color: #CE2222;
}
small.last-one-month{
    font-size: 15px;
    display: block;
    float: right;
    font-weight: bold;
}
.fromDateCol{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.toDateCol{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.history-search-button, .history-search-button:hover, .history-search-button:active, .history-search-button:focus, .history-search-button:visited{
    
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
}
@media only screen and (max-width: 600px) {
    .history-container>.row>.col-4{
        width: 100%;
        margin: 0px !important;
    }
    .history-container>.row>.col-2{
        width: 50%;
        margin: 0px !important;
    }
    .history-container>.row>.col-2.fromDateCol{
        padding-top: 0px !important;
    }
    .history-feednback-row{
        margin-top: 10px;
    }
}
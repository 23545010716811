.dashboard-container{
    background-color: #F4F4F4 !important;
    height: 100vh;
}
.dashboard-title{
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.dashboard-left{
    background-color: #e2e8fd;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}

.dashboard-left-menu-title{
    color: #000;
    
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0px;
}
.dashboard-left-menu-count{
    color: #000;
    
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
}
.dashboard-left-card{
    
    margin-bottom: 5px;
}
.dashboard-left-card-active{
    margin-bottom: 5px;
    
    
}
.dashboard-left-button{
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
    width: 100%;
    margin-top: 10px;
}
.dashboard-left-calendar{
    float: left;
}
.dashboard-interview{
    background-color: #EEEDF1;
    border: 0px solid transparent;
    
}
.dashboard-interview-name{
    color: #201e24;
    text-decoration: none;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 0px;
}
.dashboard-interview-skill{
    color: #000;
    
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
}
.dashboard-interview-company{
    color: #000;
    
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    margin-bottom: 0px;
}
.dashboard-interview-action{
    display: inline-block;
    margin-left: 10px;
}
.dashboard-interview-action>.dropdown-toggle::after{
    display: none;
}
.dashboard-interview-action-icon{
    color: #8a8595;
}
.dashboard-interview a{
    text-decoration: none;
}
.dashboard-interview-join{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
    
}
/* .dashboard-interview-join-video{
    color: #D65151;
} */
.dashboard-interview-contact{
   font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.014px;
}
.dashboard-interview-time{
    color: #000;
    
    font-size: 14.454px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.682px;
    letter-spacing: 0.022px;
    margin-bottom: 0px;
}
.dashboard-interview-today{
    
    
    font-size: 14.454px;
    font-style: normal;
    font-weight: bold;
    line-height: 33px;
    letter-spacing: 0.022px;
    border-radius: 6px;
    
    margin-bottom: 0px;
}
/* .dashboard-feednback-completed{
    border: 1px solid #000000;
    border-radius: 15px;
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
} */
/* .dashboard-feednback-completed>thead>tr>th{
    background-color: #E5DEFF !important;
} */
/* .dashboard-feednback-completed>tbody>tr>td{
    border-right: 1px solid #000000;
} */
.dashboard-feednback-completed>tbody>tr>td>a{
    text-decoration: none;
}
.dashboard-feednback-completed>tbody>tr>td>.selected, .dashboard-feednback-completed>tbody>tr>td>.pass{
    color: #128200;
}
.dashboard-feednback-completed>tbody>tr>td>.rejected, .dashboard-feednback-completed>tbody>tr>td>.fail{
    color: #CE2222;
}
.dashboard-right{
    display: block;
}
.dashboard-interview-join>span, .dashboard-interview-card>.card-body>.dashboard-interview>.card-body>.row>.col-4>.row>.col-6>.dashboard-interview-contact>span{
    margin-left: 10px;
}
.dashboard-interview-card>.card-body>a{
    text-decoration: none;
}
@media only screen and (max-width: 600px) {
    .dashboard-left{
        padding: 7px;
    }
    .dashboard-right{
        display: none;
    }
    .dashboard-left-card, .dashboard-left-card-active, .dashboard-interview-card>.card-body, .dashboard-interview-card>.card-body>.dashboard-interview>.card-body{
        padding: 5px;
    }
    .dashboard-left-card>.card-body, .dashboard-left-card-active>.card-body{
        padding: 0px;
    }
    .dashboard-left-menu-col{
        padding-right: 0px;
        padding-left: 5px;
    }
    .dashboard-left-button>span, .dashboard-interview-join>span{
        display: none;
    }
    .dashboard-left-button>.dashboard-left-calendar{
        float: none;
    }
    .dashboard-interview-card>.card-body>.dashboard-interview>.card-body>.row>.col-10{
        width: 70%;
    }
    .dashboard-interview-card>.card-body>.dashboard-interview>.card-body>.row>.col-2{
        width: 30%;
        padding-left: 0px;
    }
    .dashboard-interview-card>.card-body>.dashboard-interview>.card-body>.row>.col-2>.dashboard-interview-join{
        padding-left: 10px;
        padding-right: 10px;
    }
    .dashboard-interview-card>.card-body>.dashboard-interview>.card-body>.row>.col-8{
        width: 100%;
    }
    .dashboard-interview-card>.card-body>.dashboard-interview>.card-body>.row>.col-4{
        width: 100%;
    }
    .dashboard-interview-card>.card-body>.dashboard-interview>.card-body>.row>.col-4>.row>.col-6{
        width: 100%;
        text-align: center !important;
    }
    .dashboard-interview-card>.card-body>.dashboard-interview>.card-body>.row>.col-4>.row>.col-3{
        width: 50%;
        margin-top: 10px !important;
    }
}